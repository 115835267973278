<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          {{item.deliveryWorker.code}} ({{item.deliveryWorker.name}})
          <span v-if="item.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormContact
            :docType="docType"
            :templateType="templateType"
            ref="contact"
            class="col-12"
            select="id"
            :types="menu.contactTypes"
            v-model="formData.deliveryWorkerId"
            :validations="[
              {text: 'required!', value: $v.formData.deliveryWorkerId.$dirty && !$v.formData.deliveryWorkerId.required}
            ]">
          </DocConfigFormContact>
        </div>

        <div class="form-group">
          <sgv-input-check
            class="form"
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    contact: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        deliveryWorkerId: null,
        isDefault: false
      },
    }
  },
  validations: {
    formData: {
      deliveryWorkerId: {required}
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.isDefault = this.item.isDefault
          this.formData.deliveryWorkerId = this.item.deliveryWorker.id
          this.$refs.contact.setGroup(this.item.deliveryWorker.type)
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.contact) {
            this.formData.deliveryWorkerId = this.contact.id
            this.$refs.contact.setGroup(this.deliveryWorker.type)
          }
        })
      } else {
        this.formData.deliveryWorkerId = null
        this.formData.isDefault = false
      }
    }
  },
  created () {
    if (this.isForm) this.toggle = true
  },
  components: {
    DocConfigFormContact
  }
}
</script>

<style lang="css" scoped>
</style>
